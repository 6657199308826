@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Spline+Sans+Mono:ital,wght@0,300..700;1,300..700&display=swap");

$navbar-color: rgba(0, 0, 0, 0.85);
$text-hover-color: #bdbdbd;
$online-color: #13a10e;
$red-color: #d32f2f;
$gold-color: #ffb000;
$partner-color: #2dd5c4;
$package-card-color: #b3d1e8;

:root {
  --navbar-color: #{$navbar-color};
  --text-hover-color: #{$text-hover-color};
  --online-color: #{$online-color};
  --red-color: #{$red-color};
  --gold-color: #{$gold-color};
  --partner-color: #{$partner-color};
  --package-card-color: #{$package-card-color};
}

body {
  margin: 0;
}

a {
  cursor: pointer;
}

.hyperLink {
  color: #1976d2;
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
  cursor: pointer;
}

.hyperLink:hover {
  text-decoration-color: inherit;
}

.MuiSelect-select .MuiTypography-root {
  display: none;
}

.MuiSelect-select img {
  width: 25px;
  height: auto;
}

.MuiButton-root {
  text-transform: unset !important;
}

.languageMenuItem {
  justify-content: end !important;
}

.languageMenuItem .MuiTypography-root {
  margin-right: 7px;
}

.languageMenuItem img {
  width: 25px;
}

body .uwy.userway_p7 .userway_buttons_wrapper {
  top: unset !important;
  left: 8px !important;
  bottom: 10px !important;
}

body .uwy.uts .uai,
body .uwy.uts .uai img,
body .uwy.uts .ulsti,
body .uwy.uts .ulsti img,
body .uwy.uts .ups,
body .uwy.uts .ups img {
  width: 40px !important;
  height: 40px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

.MuiInputBase-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgb(196, 196, 196) !important;
}

.MuiInputBase-root:hover {
  border-color: black;
}

//desktop css
@media (hover: hover) and (pointer: fine) {
  .rotateAnimation {
    flex: 1 1 33%;
    cursor: pointer;
  }

  .rotateAnimation:hover .MuiContainer-root {
    transition-duration: 0.2s;
    transition-property: transform;
    transform: scale(1.1) rotate(3deg);
  }

  .rotateAnimation:hover .MuiTypography-root {
    transition: color 0.3s ease;
    color: var(--partner-color);
  }
}
